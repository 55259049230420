import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin5Line } from "react-icons/ri";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../sections/Static pages/Static.css";
import { MdAdd } from "react-icons/md";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { IoMdClose } from 'react-icons/io';
import { height, width } from '@mui/system';

function Static() {
  const [staticPages, setStaticPages] = useState([]);
  const [contentView, setContentView] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [formData, setFormData] = useState({
    id: '',
    pageName: "",
    slug: "",
    content: ""
  });

  const handlePages = async () => {
    try {
      const authToken = localStorage.getItem("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/website/page`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setStaticPages(response?.data?.data?.webSitePagesData);
    } catch (error) {
      console.log("Error fetching Pages", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    handlePages();
  }, []);

  const handleAddDataClick = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setFormData({
      id: "",
      pageName: "",
      slug: "",
      content: ""
    });
    setShowModal(false);
    setEditModal(false);
    setDeleteModal(false);
    // setSelected(null);
  };
  const handleChange = (e, editor) => {
    if (editor) {
      const data = editor.getData();
      setFormData({ ...formData, [name]: data });
    }
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleForm = async (e) => {
    e.preventDefault();
    const authToken = localStorage.getItem("jwt");

    if (!authToken) {
      toast.error("No authentication token found. Please log in.");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/website/page`,
        {
          pageName: formData.pageName,
          slug: formData.slug,
          content: formData.content,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      toast.success("Page added successfully");
      handlePages();
      handleCloseModal();
    } catch (error) {
      toast.error("Failed adding Page");
    }

  };

  // edit entry
  const handleEditClick = (data) => {
    setFormData({
      id: data._id || "",
      pageName: data.pageName || "",
      slug: data.slug || "",
      content: data.content || "",

    });
    setEditModal(true);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const authToken = localStorage.getItem("jwt");

    if (!formData.id) {
      toast.error("Error updating data");
      return;
    }

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/website/page/${formData.id}`,
        {
          pageName: formData.pageName,
          slug: formData.slug,
          content: formData.content,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      toast.success("Page updated successfully");
      handlePages();
      handleCloseModal();
    } catch (error) {
      toast.error("Failed updating Page");
    }

  };
  //   Delete API
  const handleDeleteClick = (id) => {
    setDeleteModal(true);
    setDeleteId(id);
  };

  const handleDelete = async () => {
    try {
      const authToken = localStorage.getItem("jwt");

      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/website/page/${deleteId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      toast.success(" Page Deleted Successfully");
      handlePages();
      setDeleteId("");
      handleCloseModal();
    } catch (error) {
      toast.error("Failed Deleting Page")
    }
  };
  const editorConfiguration = {
    placeholder: 'Type your content here...', // Set your placeholder text here
    toolbar: [
      'heading', '|',
      'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', '|',
      'insertImage', '|',
      'mediaEmbed',
      'undo', 'redo'
    ],
    image: {
      toolbar: ['imageTextAlternative', 'imageStyle:full', 'imageStyle:side'],
      upload: {
        types: ['jpeg', 'png', 'gif', 'bmp', 'webp'],
        base64: true // Use base64 encoding for image uploads
      }
    },
    mediaEmbed: {
      previewsInData: true,
    },
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
    <div style={{ width: "100%", marginInline: "auto", paddingTop: "50px" }}>
      <ToastContainer />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          marginBottom: "20px"
        }}
      >
        <h1
          style={{
            display: "flex",
            fontSize: "calc(1.375rem + 1.5vw)",
            fontWeight: "600",
            justifyContent: "start",
            alignItems: "center",

          }}
        >
          Static Pages{" "}
        </h1>
        <div style={{ display: "flex", marginLeft: "auto", marginRight: "10px" }}>
          <button style={{
            background: "var(--green)",
            fontSize: "18px",
            border: "none",
            color: "white",
            padding: "5px 20px",
            display: "flex",
            alignItems: "center"
          }}
            onClick={handleAddDataClick}
          >Add Static page</button>
        </div>
      </div>
      <div style={{ width: "100%", overflow: "scroll", maxHeight: "70vh",height:"max-content", paddingInline: "20px", backgroundColor: "#F8F9FA" }}>
        <table style={{ borderCollapse: "collapse", width: "100%" }}>
          <thead style={{ position: "sticky", top: "-1px" }}>
            <tr style={{ fontSize: "18px", fontWeight: "600", paddingTop: "15px" }}>
              <th style={{ borderBottom: "1px solid #dddddd", textAlign: "center", padding: "8px", paddingBlock: "10px", backgroundColor: "#F8F9FA", fontFamily: "Montserrat" }} className='whitespace-nowrap'>Page ID</th>
              <th style={{ borderBottom: "1px solid #dddddd", textAlign: "center", padding: "8px", paddingBlock: "10px", backgroundColor: "#F8F9FA", fontFamily: "Montserrat" }} className='whitespace-nowrap'>Page Name</th>
              <th style={{ borderBottom: "1px solid #dddddd", textAlign: "center", padding: "8px", paddingBlock: "10px", backgroundColor: "#F8F9FA", fontFamily: "Montserrat" }} className='whitespace-nowrap'>Slug</th>
              <th style={{ borderBottom: "1px solid #dddddd", textAlign: "center", padding: "8px", paddingBlock: "10px", backgroundColor: "#F8F9FA", fontFamily: "Montserrat" }} className='whitespace-nowrap'>Content</th>
              <th style={{ borderBottom: "1px solid #dddddd", textAlign: "center", padding: "8px", paddingBlock: "10px", backgroundColor: "#F8F9FA", fontFamily: "Montserrat" }} className='whitespace-nowrap'>Action</th>
            </tr>
          </thead>
          <tbody style={{ overflowY: "auto" }}>
            {loading ? (
              <tr>
                <td colSpan="4" style={{ textAlign: "center", padding: "20px" }}>Loading...</td>
              </tr>
            ) : staticPages?.length === 0 ? (
              <tr>
                <td colSpan="4" style={{ textAlign: "center", padding: "20px" }}>No pages available.</td>
              </tr>
            ) : (
              staticPages?.map((data, index) => (
                <tr key={index} style={{ background: "#F8F9FA" }} className='border-b'>
                  <td style={{ borderBottom: "1px solid #dddddd", textAlign: "center", padding: "8px", paddingBlock: "8px", fontFamily: "Montserrat" }}>{data?._id}</td>
                  <td style={{ borderBottom: "1px solid #dddddd", textAlign: "center", padding: "8px", paddingBlock: "8px", fontFamily: "Montserrat" }}>{data?.pageName}</td>
                  <td style={{ borderBottom: "1px solid #dddddd", textAlign: "center", padding: "8px", paddingBlock: "8px", fontFamily: "Montserrat" }}>{data?.slug}</td>
                  <td style={{ borderBottom: "1px solid #dddddd", textAlign: "center", padding: "8px", paddingBlock: "8px", fontFamily: "Montserrat" }} >
                    <button onClick={() => setContentView(index)} className='text-indigo-700'>View</button>
                    {index === contentView && (
                      <div
                        className=" fixed inset-0 z-50 overflow-auto flex items-center justify-center  bg-gray-900 bg-opacity-25 backdrop-blur-sm"
                      >
                        <div
                          className=" relative bg-white rounded-xl flex flex-col p-8 overflow-auto justify-center" style={{ width: "100vh", height: "80%" }}
                        >
                          <div className='w-full border-b p-2 absolute top-0 right-0 flex justify-end'>
                            <IoMdClose className='text-slate-600' style={{ fontSize: "30px", cursor: "pointer" }} onClick={() => setContentView(null)} />
                            </div>
                          <div className='overflow-auto mt-16'>
                            <div dangerouslySetInnerHTML={{ __html: data?.content }}>

                            </div>

                          </div>

                        </div>
                      </div>
                    )}
                  </td>
                  <td style={{ borderBottom: "1px solid #dddddd", textAlign: "center", padding: "8px", paddingBlock: "8px", fontFamily: "Montserrat" }} className='text-center'>
                    <div style={{ display: "flex", flexDirection: "row" }} className='justify-center'>
                      <FaRegEdit size={20} color={"var(--green)"} style={{ marginInline: "5px", display: "flex" }} onClick={() => handleEditClick(data)} />
                      <RiDeleteBin5Line size={20} color={"red"} style={{ marginLeft: "10px" }} onClick={() => handleDeleteClick(data._id)} />
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      {/* Add new data */}
      {showModal && (
       <div 
       className="fixed inset-0 z-50 overflow-auto flex items-center justify-center bg-gray-900 bg-opacity-25 backdrop-blur-sm"
     >
       <div 
         className="bg-white rounded-xl flex flex-col px-5 py-4 overflow-auto"
         style={{ width: "50vw", maxWidth: "630px", height: "auto", maxHeight: "85vh", overflowX: "hidden" }}
       >
         <form onSubmit={(e) => { handleForm(e) }} 
        //  className='overflow-auto'
          >
           <div style={{ fontSize: "18px", fontWeight: 600, marginBottom: "15px" }}>Add Static Page</div>
     
           <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: "10px" }}>
             <label style={{ width: "30%", padding: "5px", fontSize: "16px" }}>Page Name:</label>
             <input
               type="text"
               style={{ flex: 1, padding: "8px", border: "1px solid #d3d3d3", borderRadius: "4px", fontSize: "16px", boxSizing: "border-box" }}
               placeholder="Enter Page name"
               value={formData.pageName}
               name="pageName"
               onChange={handleChange}
               required
             />
           </div>
     
           <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: "10px" }}>
             <label style={{ width: "30%", padding: "5px", fontSize: "16px" }}>Slug:</label>
             <input
               type="text"
               style={{ flex: 1, padding: "8px", border: "1px solid #d3d3d3", borderRadius: "4px", fontSize: "16px", boxSizing: "border-box" }}
               placeholder="Enter Slug"
               value={formData.slug}
               name="slug"
               onChange={handleChange}
               required
             />
           </div>
     
           <div style={{ marginBottom: "10px" }}>
             <label style={{ display: "block",padding: "5px", fontSize: "16px" }}>Content:</label>
             <CKEditor
               editor={ClassicEditor}
               data=""
               onChange={(event, editor) => {
                 const data = editor.getData();
                 setFormData({ ...formData, content: data });
               }}
               config={{
                 ckfinder: {
                   uploadUrl: `${process.env.REACT_APP_BASE_URL}/website/file-upload`
                 },
                 placeholder: 'Type your content here...',
                 toolbar: [
                   'heading', '|', 'bold', 'italic', '|',
                   'link', 'bulletedList', 'numberedList', 'blockQuote', '|',
                   'insertTable', '|', 'imageUpload', '|',
                   'mediaEmbed',
                   'undo', 'redo'
                 ],
                 image: {
                   toolbar: [
                     'imageTextAlternative',
                     'imageStyle:full',
                     'imageStyle:side'
                   ]
                 },
    mediaEmbed: {
      previewsInData: true, 
    },
               }}
             />
           </div>
     
           <div style={{ display: "flex", justifyContent: "space-between", marginTop: "15px" }}>
             <button
               style={{
                 background: "var(--green)",
                 border: "none",
                 borderRadius: "4px",
                 padding: "8px 12px",
                 color: "white",
                 fontSize: "16px",
                 flex: 1,
                 marginRight: "5px",
                 cursor: "pointer"
               }}
               type="submit"
             >
               Add
             </button>
             <button
               style={{
                 background: "#d3d3d3",
                 border: "none",
                 borderRadius: "4px",
                 padding: "8px 12px",
                 color: "black",
                 fontSize: "16px",
                 flex: 1,
                 marginLeft: "5px",
                 cursor: "pointer"
               }}
               onClick={handleCloseModal}
             >
               Cancel
             </button>
           </div>
         </form>
       </div>
     </div>
     

      )}
      {/* Update data */}
      {editModal && (
        <div
        className="fixed inset-0 z-50 overflow-auto flex items-center justify-center bg-gray-900 bg-opacity-25 backdrop-blur-sm"
        >
          <div 
         className="bg-white rounded-xl flex flex-col px-5 py-4 overflow-auto"
         style={{ width: "50vw", maxWidth: "630px", height: "auto", maxHeight: "85vh", overflowX: "hidden" }}
       >
            <form onSubmit={(e) => { handleUpdate(e) }} 
            // className='overflow-auto'
            >
              <div style={{ fontSize: "20px", fontWeight: 600, marginBottom: "10px" }}>Edit Static Page</div>

              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: "10px" }}>
             <label style={{ width: "30%", padding: "5px", fontSize: "16px" }}>Page Name:</label>
             <input
               type="text"
               style={{ flex: 1, padding: "8px", border: "1px solid #d3d3d3", borderRadius: "4px", fontSize: "16px", boxSizing: "border-box" }}
               placeholder="Enter Page name"
               value={formData.pageName}
               name="pageName"
               onChange={handleChange}
               required
             />
           </div>

           <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: "10px" }}>
             <label style={{ width: "30%", padding: "5px", fontSize: "16px" }}>Slug:</label>
             <input
               type="text"
               style={{ flex: 1, padding: "8px", border: "1px solid #d3d3d3", borderRadius: "4px", fontSize: "16px", boxSizing: "border-box" }}
               placeholder="Enter Slug"
               value={formData.slug}
               name="slug"
               onChange={handleChange}
               required
             />
           </div>
           <div style={{ marginBottom: "10px" }}>
             <label style={{ display: "block",padding: "5px", fontSize: "16px" }}>Content:</label>
             <CKEditor
                  config={editorConfiguration}
                  editor={ClassicEditor}
                  data={formData.content}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setFormData({ ...formData, ['content']: data });
                  }}
                />
             
           </div>


           <div style={{ display: "flex", justifyContent: "space-between", marginTop: "15px" }}>
             <button
               style={{
                 background: "var(--green)",
                 border: "none",
                 borderRadius: "4px",
                 padding: "8px 12px",
                 color: "white",
                 fontSize: "16px",
                 flex: 1,
                 marginRight: "5px",
                 cursor: "pointer"
               }}
               type="submit"
                >
                  Update
                </button>
                <button
                  style={{
                    background: "#d3d3d3",
                    border: "none",
                    borderRadius: "4px",
                    padding: "8px 12px",
                    color: "black",
                    fontSize: "16px",
                    flex: 1,
                    marginLeft: "5px",
                    cursor: "pointer"
                  }}
                  onClick={handleCloseModal}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>

      )}

      {/* Delete Entry */}
      {deleteModal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 99,
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "5px",
              width: "450px",
            }}
          >
            <div style={{ fontSize: "20px", fontWeight: 600, marginBottom: "10px" }}>Are you sure you want to delete?</div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <button style={{ display: "flex", margin: "10px", fontSize: "16px", background: "var(--green)", width: "100px", justifyContent: "center", border: "none", borderRadius: "5px", padding: "5px 10px", color: "white", cursor: "pointer" }} onClick={handleDelete}>Delete</button>
              <button style={{ display: "flex", margin: "10px", fontSize: "16px", background: "#d3d3d3", width: "100px", justifyContent: "center", border: "none", borderRadius: "5px", padding: "5px 10px", color: "black", cursor: "pointer" }} onClick={handleCloseModal}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  </div>
  )
}

export default Static