import axios from "axios";
import React, { useEffect, useState } from "react";
import Header from "../Header";
import { useParams } from "react-router-dom";
import YoutubeSection from "../../sections/YoutubeSection";
import Footer from "../Footer";

function StaticPages() {
  const [headerData, setHeaderData] = useState([]);
  const [imgIndex, setImgIndex] = useState([]);
  const params = useParams();
  const path = encodeURIComponent(params?.slug);
  const isMobile = window.innerWidth <= 767;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleGetHeader = async () => {
    try {
      const authToken = localStorage.getItem("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/website/page/${path}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log(
        "response?.data?.data?.pageData",
        response?.data?.data?.pageData
      );
      setHeaderData(response?.data?.data?.pageData);
    } catch (error) {
      console.log("Error fetching Pages", error);
    }
  };

  useEffect(() => {
    handleGetHeader();
  }, [path]);

  useEffect(() => {
    // Extract image indices only when headerData changes
    const extractImageIndices = (content) => {
      const parts = content.split(
        /(<h[1-6][^>]*>.*?<\/h[1-6]>|<figure.*?<\/figure>)/g
      );
      let tmpPart = [];
      parts.filter(el=>el.length>0).forEach((part, index) => {
        if (part.startsWith("<figure")) {
          tmpPart.push(index);
        }
      });
      setImgIndex(tmpPart)
    };

    if (headerData?.content) {
      // Decode HTML entities if necessary
      const decodedContent = headerData.content
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">");
      extractImageIndices(decodedContent);
    }
  }, [headerData]);

  // Function to split content into parts (text, iframes, headings) 
  // Function to split content into sections (heading + related content)
  const splitIntoSections = (content) => {
    const parts = content.split(
      /(<h[1-6][^>]*>.*?<\/h[1-6]>|<figure.*?<\/figure>)/g
    );

    let sections = [];
    let currentSection = null;

    parts.forEach((part,index) => {
      if(part!=="")
      {
        console.log("paring",part)
        if (part.trim().startsWith("<h")) {
          if (currentSection) sections.push(currentSection); // Push previous section
          currentSection = { heading: part, content: "" }; // Create new section
        } else if (part.trim().startsWith("<figure")) {
          if (currentSection) sections.push(currentSection); // Push previous section
          currentSection = { heading: "", content: part }; // Create a new section for the figure
        }else if (part.trim().startsWith("<p")) {
          currentSection.content = part
        } else if (currentSection) {
          currentSection.content = part
        }
      }
    });
  
    if (currentSection) sections.push(currentSection); // Push the last section

    return sections;
  };

  // Function to render sections with alternating background colors
  const renderContent = (content) => {
    if (!content) return null;

    // Decode HTML entities
    const decodedContent = content.replace(/&lt;/g, "<").replace(/&gt;/g, ">");

    // Split content into sections (heading + content)
    const sections = splitIntoSections(decodedContent);

    // Determine if viewport is mobile
    const isMobile = window.innerWidth <= 767;

    // Inline styles for section background color alternation
    const sectionStyle = (index) => ({
    marginTop:"-15px",
    marginBottom:"-25px",
      backgroundColor: index % 2 === 0 ? "white" : "#f0f0f0",
      // padding: !isMobile ? "20px" : imgIndex.includes(index) ? "0px" : "10px",
      fontFamily: "Montserrat, sans-serif",
      textAlign: "justify",
      paddingBottom:"10px",
      paddingInline: !isMobile ? "10%" : imgIndex.includes(index) ? "0%" : "3%",
      // marginBottom: isMobile ? "10px" : "10px",
    });

    const headingStyle = {
      paddingTop:"10px",
      fontFamily: "Montserrat, sans-serif",
      // marginBottom: isMobile ? "10px" : "20px",
    };

    return (
      <div >
        {sections.map((section, index) => (
          <div key={index} style={sectionStyle(index)}>
            <div
              className="heading-container"
              style={headingStyle}
              dangerouslySetInnerHTML={{ __html: section.heading }}
            />
            <div
              className="content-container"
              dangerouslySetInnerHTML={{ __html: section.content }}
            />
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <Header />
      <div className="" style={{ paddingTop: isMobile ? "16%" : "7%" }}>
        <div className="">
          {renderContent(headerData?.content)}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default StaticPages;
