import styles from "./Header.module.css";
import logo from "./../../../assets/logo.png";
import { AiFillApple } from "react-icons/ai";
import { FaGooglePlay } from "react-icons/fa";
import apple from "../../../assets/apple.png";
import playStore from "../../../assets/playstore.png";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { IoMdClose, IoMdMenu } from "react-icons/io";

const Header = (props) => {
    const [headerData, setHeaderData] = useState([]);
    const [mobileOpen, setMobileOpen] = useState(false);
    const [desktopMenuOpen, setDesktopMenuOpen] = useState(false);
    const [allData, setAllData] = useState([]);

    const handleHeroSection = async() => {
        try {
          const authToken = localStorage.getItem("jwt");
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/website/home`,
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
            }
          );
          const allData = response.data.data.homePageData;
          setAllData(allData);
    
        } catch (error) {
          console.log("Error fetching Hero Section", error);
        } 
      };
    
      useEffect(() => {
        handleHeroSection();
      },[]);
    
    const navigate = useNavigate();
 const toHome = () => {
    navigate(`/`);
 }
    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const handleDesktopMenuToggle = () => {
        setDesktopMenuOpen((prevState) => !prevState);
    };

    const handleNavigation = (link) => {
        navigate(`/staticPages/${link}`);
    };

    const handleGetHeader = async () => {
        try {
            const authToken = localStorage.getItem("jwt");
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/website/header`,
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            setHeaderData(response.data.data.headersData);
        } catch (error) {
            console.log("Error fetching Pages", error);
        }
    };

    useEffect(() => {
        handleGetHeader();
    }, []);

    const firstFourItems = headerData.slice(0, 4);
    const remainingItems = headerData.slice(4);

    const ResponsiveNavbar = (
        <div onClick={() => setMobileOpen(false)}>
            <div className="pt-3.5 w-full" style={{ padding: '0 10px', maxWidth: '99%', overflowX:"hidden", marginInline:"auto" }}> 

            <ul className="flex flex-col  justify-center " 
            >
                    {headerData?.map(
                        (i, index) =>
                            i.link !== "" && (
                                <li
                                    key={index}
                                    className={`${
                                        i.type === "static"
                                            ? "flex cursor-pointer"
                                            : "flex"
                                    }`}
                                    onClick={() =>
                                        i.type === "static"
                                            ? handleNavigation(i.link)
                                            : null
                                    }
                                    style={{borderBottom:"1px solid #d3d3d3", marginRight:"2rem", justifyContent:"left", padding:"15px 0"}}
                                >
                                    {i.type === "static" ? (
                                        <span className="text-cyan-700 hover:text-cyan-600 flex items-center uppercase text-lg font-bold whitespace-nowrap">
                                            {i.name}
                                        </span>
                                    ) : (
                                        <a
                                            href={i.link}
                                            className="text-cyan-700 hover:text-cyan-600 flex items-center uppercase text-lg font-bold whitespace-nowrap"
                                            style={{ textDecoration: "none" }}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {i.name}
                                        </a>
                                    )}
                                </li>
                            )
                    )}
                </ul>
            </div>
        </div>
    );

    const DesktopDropdownMenu = (
        <div
            className="absolute top-15 shadow-xl z-50"
            style={{
                left: "650px",
                right: "15px", // Slightly move the menu to the right
                transform: desktopMenuOpen ? "translateX(0)" : "translateX(100%)",
            }}
        >
            <div className="flex justify-end p-2">
                {/* <IoMdClose
                    className="text-cyan-700 text-4xl cursor-pointer"
                    onClick={handleDesktopMenuToggle}
                /> */}
            </div>
            {/* <ul className="flex flex-col gap-5 p-4">
             */}
            <ul className="flex flex-col gap-4 p-3 w-5/6 -ml-0" style={{ width:"200px", background:"#fff",marginTop:"-15px"}}>
                {remainingItems?.map(
                    (i, index) =>
                        i.link !== "" && (
                            <li
                                key={index}
                                className={`${
                                    i.type === "static"
                                        ? "flex cursor-pointer"
                                        : "flex"
                                }`}
                                style={{borderBottom:"1px solid #d3d3d3"}}
                                onClick={() =>
                                    i.type === "static"
                                        ? handleNavigation(i.link)
                                        : null
                                }
                            >
                                {i.type === "static" ? (
                                    <span className="text-cyan-700 hover:text-cyan-600 flex items-center uppercase text-lg font-bold whitespace-nowrap">
                                        {i.name}
                                    </span>
                                ) : (
                                    <a
                                        href={i.link}
                                        className="text-cyan-700 hover:text-cyan-600 flex items-center uppercase text-lg font-bold whitespace-nowrap"
                                        style={{ textDecoration: "none" }}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {i.name}
                                    </a>
                                )}
                            </li>
                        )
                )}
            </ul>
        </div>
    );

    return (
        <div className={styles["container"]}>
            <div className={styles["left"]}>
                <img src={logo} alt="logo" />
                <h3 onClick={toHome}
                    style={{ padding: "0px", margin: "0px" }}
                    className="max-[420px]:text-xl"
                >
                    Healin Roots
                </h3>
            </div>
            <div className="pt-3.5 flex flex-row w-full min-[1140px]:block hidden relative ">
                <ul className="flex gap-5 justify-center w-full">
                    {firstFourItems.map(
                        (i, index) =>
                            i.link !== "" && (
                                <li
                                    key={index}
                                    className={`${
                                        i.type === "static"
                                            ? "flex cursor-pointer"
                                            : "flex"
                                    }`}
                                    onClick={() =>
                                        i.type === "static"
                                            ? handleNavigation(i.link)
                                            : null
                                    }
                                >
                                    {i.type === "static" ? (
                                        <span className="text-cyan-700 hover:text-cyan-600 flex items-center uppercase text-lg font-bold whitespace-nowrap">
                                            {i.name}
                                        </span>
                                    ) : (
                                        <a
                                            href={i.link}
                                            className="text-cyan-700 hover:text-cyan-600 flex items-center uppercase text-lg font-bold whitespace-nowrap"
                                            style={{ textDecoration: "none" }}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {i.name}
                                        </a>
                                    )}
                                </li>
                            )
                    )}
                    {remainingItems.length > 0 && (
                           <div className="flex items-center ml-auto mr-2" > {/* Adjust mr-5 to move left */}
                            {desktopMenuOpen ? (
                                <IoMdClose
                                    className="text-cyan-700 text-4xl mx-2 mt-2.5 cursor-pointer"
                                    onClick={handleDesktopMenuToggle}
                                />
                            ) : (
                                <IoMdMenu
                                    className="text-cyan-700 text-4xl mx-2 mt-2.5 cursor-pointer"
                                    onClick={handleDesktopMenuToggle}
                                />
                            )}
                        </div>
                    )}
                </ul>
                {desktopMenuOpen && remainingItems.length > 0 && DesktopDropdownMenu}
            </div>
            <div className={styles["right"]}>
                <div className={styles["btns"]}>
                    {mobileOpen ? (
                        <IoMdClose
                            className="text-cyan-700 text-4xl max-[1140px]:block hidden mx-2 mt-2.5"
                            onClick={handleDrawerToggle}
                        />
                    ) : (
                        <IoMdMenu
                            className="text-cyan-700 text-4xl max-[1140px]:block hidden mx-2 mt-2.5"
                            onClick={handleDrawerToggle}
                        />
                    )}
                    <div
                        className={styles["btn"]}
                        onClick={() => {
                            window.open(allData?.app_section_app_store , "_target");
                        }}
                    >
                        <AiFillApple />
                        <p>App Store</p>
                    </div>
                    <div
                        className={styles["btn"]}
                        onClick={() => {
                            window.open(
                                allData?.app_section_play_store,
                                "_target"
                            );
                        }}
                    >
                        <FaGooglePlay />
                        <p>Play Store</p>
                    </div>
                    <div className={styles["mobile-btn"]}>
                        <img
                            src={apple}
                            style={{ width: "40px", height: "40px"}}
                            onClick={() => {
                                window.open(allData?.app_section_app_store , "_target");
                            }}
                        />
                        <img
                            src={playStore}
                            style={{ width: "35px", height: "35px" }}
                            onClick={() => {
                                window.open(
                                    allData?.app_section_play_store,
                                    "_target"
                                );
                            }}
                        />
                    </div>
                </div>
            </div>
            {mobileOpen && (
                <div
                    onClose={handleDrawerToggle}
                    className="block lg:hidden xl:hidden 2xl:hidden absolute top-14 left-0 bg-white w-full shadow-xl"
                >
                    {ResponsiveNavbar}
                </div>
            )}
        </div>
    );
};

export default Header;
